import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatData: null,
};

const Chat = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setChatData: (state, action) => {
      state.chatData = action.payload;
    },
  },
});

export const { setChatData } = Chat.actions;

export default Chat.reducer;
