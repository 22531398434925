import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";

// ----------------------------------------------------------------------

const Logo = forwardRef(() => {
  return (
    <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
      <Box
        component="div"
        sx={{
          width: "140px",
          height: "auto",
          maxWidth: "100%",
          flexShrink: 0,
          padding: 2,
          "@media (max-width: 1350px)": {
            maxWidth: "70%",
            width: "110px",
          },
          "@media (max-width: 900px)": {
            maxWidth: "60%",
            width: "100px",
          },
        }}
      >
        <img
          src={"assets/logo.png"} // Replace this with the path to your logo image
          alt="Logo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
