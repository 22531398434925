import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import DashboardLayout from "./layouts/dashboard";

const Home = lazy(() => import("./pages/Home"));
const Page404 = lazy(() => import("./pages/Page404"));

export default function Router({ handleThemeChange, textSize, setTextSize }) {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: "home", element: <Home /> },
      ],
    },

    {
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
