import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AlertsReducer from "./slices/AlertsSlice";
import MasterReducer from "./slices/MasterSlice";
import ChatReducer from "./slices/ChatSlice";

const rootReducer = combineReducers({
  Alerts: AlertsReducer,
  Master: MasterReducer,
  Chat: ChatReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
