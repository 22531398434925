import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Logo from "src/components/logo";

const APP_BAR_MOBILE = 64;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const StyledHeader = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 40,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  return (
    <StyledRoot>
      <StyledHeader>
        <Logo />
      </StyledHeader>
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
