import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Suspense, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import { CircularProgress } from "./mui";
import QueriesHistoryProvider from "./store/QueriesHistory";
import palette from "./theme/palette";
// import typography from './theme/typography';
import shadows from "./theme/shadows";
import customShadows from "./theme/customShadows";
import darkPalette from "./theme/darkPalette";
import { themeKeys } from "./utils/helpers";
import customTypography from "./theme/customTypography";

export default function App() {
  // eslint-disable-next-line consistent-return
  const setDark = (theme) => {
    if (theme === themeKeys.dark) {
      return true;
    }
    if (theme === themeKeys.light) {
      return false;
    }
  };
  const [isDarkMode, setIsDarkMode] = useState(null);
  const [textSize, setTextSize] = useState(16);
  const currentTheme = localStorage.getItem("theme");

  const handleThemeChange = (value) => {
    setIsDarkMode(value);
  };

  const themeOptions = useMemo(() => {
    setIsDarkMode(setDark(currentTheme));
    return {
      palette: isDarkMode ? darkPalette : palette,
      shape: { borderRadius: 6 },
      typography: customTypography(textSize),
      shadows: shadows(),
      customShadows: customShadows(),
    };
  }, [currentTheme, isDarkMode, textSize]);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider themeOptions={themeOptions}>
          <StyledChart />
          <Suspense
            fallback={
              <div
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <QueriesHistoryProvider>
              <Router
                handleThemeChange={handleThemeChange}
                textSize={textSize}
                setTextSize={setTextSize}
              />
              <Toaster position="top-right" reverseOrder={false} />
            </QueriesHistoryProvider>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
